@import "../../../assets/theme/constants.scss";
.loadingStyle{
  font-size: 20px !important;
  color: '#6b6b6b' !important;
 text-align: center !important;
 margin-top: 10px !important;
//  height: 200px !important
//  font-family: 'Ubuntu,sans-serif' !important;
  
}
.titleStyle{
  font-size: 15px !important;
  text-align: left !important;
  padding-left: 28px !important;
  padding-top: 0 !important;
}

.linearProgressStyle{
  height: 25px !important;
  margin: 30px 5px 5px 30px !important;
  background-color: #dcdcdc !important;

}
.percentageStyle{
  margin-top: 25px !important;
  padding-left: -50px !important;
}