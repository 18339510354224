@import "../../../assets/theme/constants.scss";

.errorStyle {
  min-width: 5px !important;
  background-color: $red  !important;
  margin-left: 0px !important;
  margin-right: 3px !important;
  height: 40px !important;
  z-index: 1 !important;
  position: 'sticky' !important;
}

.errorStyle {
  min-width: 3px !important;
  background-color: #800000   !important;
  color: #800000 !important;
  // margin-left: 4px !important;
  //margin-right: px !important;
  

}
.errorStyle1 {
  min-width: 3px !important;
  background-color: #800000  !important;
  color:$gray !important;
  // margin-left: 4px !important;
  //margin-right: px !important;
  

}

.profileIconStyle{
  color: rgba(0, 0, 0, 0.54) !important;
   margin-right: 15px !important;
}

.missingInfo{
  padding: 1px 4px;
  font-size: 13px;
  background: #fcce;
  border: 1px solid red;
  border-radius: 3px;
  display: inline-block;
  min-width: 70px;
  font-weight: 600;
  text-align: center;
}
.displayNameStyle{
   color : rgb(59, 47, 3)!important;
  font-weight: 500 !important;
 
}
.stackStyle{
  margin-left: 6px !important;
}