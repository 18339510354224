

// .trapezoid1 {
// 	border-bottom: 60px solid #0066ff;
// 	border-left: 25px solid transparent;
// 	border-right: 25px solid transparent;
// 	height: 0;
// 	width: 300px;
// }
// .trapezoid2 {
// 	border-bottom: 60px solid #1a75ff;
// 	border-left: 14px solid transparent;
// 	border-right: 14px solid transparent;
// 	height: 0;
// 	width: 250px;
// 	margin-left: 25px;
// }
// .trapezoid3 {
// 	border-bottom: 60px solid #3c8aff;
// 	border-left: 52px solid transparent;
// 	border-right: 80px solid transparent;
// 	height: 0;
// 	width: 223px;
// 	// margin-left: 38px;
// }

// .triangleUp {
// 	width: 0;
// 	height: 0;
// 	border-left: 45px solid transparent;
// 	border-right: 45px solid transparent;
// 	border-bottom: 60px solid #84b5ff;
// 	margin-left: 52px;
// }
.pyramidContainer {
  text-align: center;
}

.inside-text {
  position: absolute;
  bottom: -40px;
}
.popupTitle{
  font-weight: 600 !important;
}
.textSpan{
  min-width: 200px;
  // margin-left: 30px !important;
  margin-top:25px;
}
.countSpan{
  color:white;
  width: 20px;
 //  min-width: max-content;
  margin-left: 41px !important;
  align-items: center;
  margin-top:25px;
}
.countSpan1{
  color:white;
  width: 20px;
  // min-width: max-content;
  margin-left: 91px !important;
  margin-top:25px;
}
.countSpan2{
  color:white;
  width: 20px;
  // min-width: max-content;
  margin-left: 141px !important;
  margin-top:25px;
}

.countSpan3{
  margin-left: -9px !important;
  width: 20px;
  color:white;
  margin-top:25px;
}
.popOverStyle{
    overflow-y: scroll !important;
  // m-height: '200px' !important;
}

.loadingStyle{
  font-size: 17px !important;
  color: '#6b6b6b' !important;
 text-align: center !important;
 margin-top: 105px !important;
}
.cardContentStyle{
background-color: '#ebecf2' !important;
padding: '10px' !important;
}


