.iconbutton {
  align-items: 'left';
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 100%;
  height: 40px;
  width: 40px;
}

.title{
  color: #252525;
  font-weight: 600;
  text-align: left;
  font-size: 20px !important;
  padding-right: 167px !important;
  font: normal normal normal 22px/33px 'PT Sans';
}
.stack {
  // padding-top: 20px;
  overflow: 'scroll' !important
}

.box {
  display: flex;
  flex-direction: row;
  background-color: #F4F4F4;
  // height: 56px;
  // min-width: 400px !important;
  // width: 300px !important;
  align-Items: center;
  justify-content: center;
  border-radius: 6px;
  overflow: 'scroll' !important
}

.slider{
   margin: 5px;
}

.percentage{
  padding: 0 10px 0 10px !important;
  margin-right: 0 5px 0 5px !important;
}

.drawerbutton{
  width: 100px;
   font-size: 15px;
   text-transform: capitalize !important;
padding-bottom: 7px !important;
margin-right: 16px !important;
}  
.loading {
  color: white !important;
  margin-right: 10px;
}

.percentage{
  padding: 0 10px 0 10px !important;
  margin-right: 0 5px 0 5px !important;
}

.numberText{
  width: '100px' !important;
  padding-left: '10px'!important;
  // padding-top:' 2px'!important;
}