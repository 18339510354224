@import '../../assets/theme/constants.scss';

.nestedNav {
    height: 60px;
    width: 100%;
    display: inline-block;
    color: rgb(0, 0, 0);
    background: #f4f4f4;
    text-decoration: none !important;
    font-size: 14px !important;
    padding: 10px 0 5px 30px;
}

.nestedList {
    width: 100%;
    list-style-type:none;
    margin: 0 !important;
    padding: 0 !important;
}

.activeUserRole{
    display: inline-block;
    font-weight: 600;
    background: #ffc107;
    padding: 1px 3px;
    border-radius: 3px;
    margin-bottom: 7px;
}

