@import "../../assets/theme/constants.scss";

.pageHeader {
 margin: 0;
 padding-bottom: 7px;
 border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.allocationBtnStyle {
  text-transform: capitalize !important;
  // margin-right: 16px !important;
  margin-bottom: 7px !important;
  float: right !important;
}
.clearBtnStyle {
  text-transform: capitalize !important;
}
.switchStyle{
  padding-right: 15px !important;
}
.errorStyle{
  position: 'fixed' !important;
  min-width: 950px !important;
  top: 2px !important;
  z-Index: 90 !important;
}
.expiredStyle {
  background-color: $red  !important;
  min-width: 10px !important;
  margin-right: 10px !important;
  float: left;
  min-height: 100%;
}
.disableStyle{
  background-color: $gray !important;
  min-width: 10px !important;
  margin-right: 10px !important;
  float: left;
  min-height: 100%;
}
.resignedStyle{
  background-color: #800000 !important;
  min-width: 5px !important;
  margin-right: 10px !important;
  float: left;
  min-height: 100%;
  // margin-bottom: 25px !important;

}
.divStyle{
  padding: 10px 10px 0px 16px !important;
  // margin-right: 10px !important;
  background-color: '#f4f4f4' !important;
  min-height: 70% !important;
  float: right !important;
  text-align: right !important;
  //  margin-bottom: 10px;
}