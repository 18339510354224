
@import "../../assets/theme/constants.scss";

.headerStyle {
  margin: 0 !important;
  padding-bottom: 7px !important;
  font-weight: 400 !important;
  font-size: "24px" !important;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.filterIcon {
  float: left !important;
  cursor: pointer;
 
}
.filterIconButtonClicked {
 color: $primary !important;
//  border: 1px solid $primary !important;
  cursor: pointer;
 
}
.filterIconButton {
  color: $black !important;
  // border: 1px solid $black !important;
  opacity: 0.8;
   cursor: pointer;
  
 }
.titleStyle{
 font-weight: 600 !important;
 font-size: 17px !important;
}

.loadingStyle{
  font-size: 16px !important;
  color: '#6b6b6b' !important;
 text-align: center !important;
 margin-top: 10px !important;
//  height: 200px !important
//  font-family: 'Ubuntu,sans-serif' !important;
  
}
.verticalDividerStyle{
  margin-top: 15px !important;
  margin-left: 15px !important;
}
.linearProgressStyle{
  height: 50px !important;
}