@import "../../assets/theme/constants.scss";

.stackStyle {
  padding-left: 10px !important;

  .userProfile_name {
    font-size: 22px !important;
    padding: 0 !important;
    font-weight: 400;
  }
  .userProfileUsername{
    padding: 0px 0px 0px 0px;
    height: 18px !important;
  }

  .designation {
    font-size: 14px !important;
  }

}

.iconStyle {
  min-width: '80px'
}

.tecStackStyle {
 
  padding-bottom: 20px !important;
}

.tecChipStyle {
  font-size: 14px !important;
  margin-top: 5px !important;
  margin-right: 5px !important;
  height: 20px !important;
}

.profile {
  height: 80px !important;
  width: 80px !important;
  // margin-bottom: 10px !important;
  background-color: $primary !important;

  .profileAvatar {
    font-size: 36px !important;
    // color: $primary;
    // background-color: $primary;
    // font-weight: 700;
    padding-bottom: 10px !important;
    text-transform: uppercase;
  }
}

.expiredStyle {
  background-color: $red  !important;
  min-width: 10px !important;
  margin-right: 10px !important;
  float: left;
  min-height: 100%;
}
.futureStyle{
  background-color: $purple !important;
  min-width: 10px !important;
  margin-right: 10px !important;
  float: left;
  min-height: 100%;
}

.activeStyle {
  background-color: $green  !important;
  min-width: 10px !important;
  margin-right: 10px !important;
  float: left;
  min-height: 100%;
}

.tecChipStyle{
   border-radius: 10px !important;
  height: 18px !important;
  color: $black !important;
  font-weight: 800px !important;
  margin-bottom: 1px !important;
}
.loaderStyle{
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  position: absolute !important;
  float: right !important;
  margin: auto !important;
  
}
.textStyle{
  margin-left: 5px !important;
  text-align: center !important;
}