.layoutStyle {
    float: right !important;
}

.tableIconStyle {
    margin: 0 5px 0 5px !important;
}

.iconDivider {
    border-color: #868181 !important;
    border-width: 1px !important;
}

.typograpyStyle {
    margin: 2px 0 0 10px !important;
    white-space: nowrap !important;

    @media screen and (max-width: 768px) {
       display: none !important;
      }
}

.checkboxView {
    max-width: 500px !important;
    list-style-type: none;
    height: 200px !important;
    overflow-y: scroll;

    ul {
        list-style-type: none;
        padding-left: 0;
        text-align: left;

        li {
            display: inline-block;
            width: 50%;
            padding-left: 29px !important;
        }
    }
}

.allSelectCheckbox {
    max-width: 850px !important;
    list-style-type: none;
    overflow-y: scroll;

    ul {
        list-style-type: none;
        padding-left: 0;
        text-align: left;

        li {
            display: inline-block;
            width: 33.3%;
            padding-left: 29px !important;
        }
    }
}

// .checkboxView::-webkit-scrollbar {
//     // height: 8px !important;
// }

.checkboxView::-webkit-scrollbar-track {
    background-color: #ccc !important;
}

.checkboxView::-webkit-scrollbar-thumb {
    background-color: rgb(146, 146, 146) !important;
}

.menuStyle {
    // height: 30px !important;
    // max-width: 620px !important;
    // width: 570px !important;
}

.searchBox {
    min-width: 810px !important;
    margin: 20px 20px 10px 20px !important;
}