@import "../../../assets/theme/constants.scss";
.errorStyle{
  width: 5px !important;
  background-color: $red !important;
  margin-right: 3px !important;
}
.missingInfo{
  padding: 1px 4px;
  font-size: 13px;
  background: #fcce;
  border: 1px solid red;
  border-radius: 3px;
  display: inline-block;
  min-width: 70px;
  font-weight: 600;
  text-align: center;
}