@import "../../../assets/theme/constants.scss";
.worningStyle{
  color: $red !important;
}
.iconStyle{
  width: 50px !important;
  height: 50 px !important;
}

.loading {
  color: white !important;
  margin-right: 10px;
}