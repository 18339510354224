@import "../../../assets/theme/constants.scss";
.dialogContent {
    padding: 8px 24px !important;
  
  }
  .cardActionStyle{
    padding: 8px 8px !important;
  }
  .dialogHeading{
font-weight: 100 !important;
  }
  .divider{
    margin: 10px !important;
  }
  .infoCircle {
    background: $primary;
    color: white;
    font-size: 17px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    // font-weight: 700;
    text-transform: uppercase;
  }
.date{
    font-size: 12px;
    color: grey;
}
.comment{
  p{
    margin: 5px 0px !important;
  }
    padding:0px;
    text-align: justify;
    cursor: pointer;
}
.readMore{
  color: $primary;
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: pointer;
}
.extraContent{
  color: black;
  font-weight: 500;
}
.loading {
  color: white !important;
  margin-right: 10px;
}

.dataContent{
  padding: 0px 24px 16px 24px !important;
  min-width: 600px !important
}
.btnStyle {
  text-transform: capitalize !important;
  padding-bottom: 7px !important;
  // margin-right: 16px !important;
}
.cardActionStyle{
  padding: 8px 0px !important;
}