.title-view{
    padding: 10px 10px 10px 10px;
}

  .scrollerExpenision {
    height: fit-content;
    max-height: 300px;
    max-width: 610px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    padding: 10px 10px 10px 10px;
  }

  .scrollerCard {
    height: fit-content;
    max-height: 62vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 10px 10px 10px;
  }

  .detailLables{
    font-weight: bold !important;
    margin-top: 10px;
  }

  .grid1{
    font-weight: 100;
    display: inline-block;
  }
  
  .loading {
    color: white !important;
    margin-right: 10px;
  }

  .detailLables1{
display: none;
  }
