.pageStyle{
    float: right !important;
    display:"flex"
}
.dialogCard {
   // padding: 16px 0 16px 0 !important;
    align-items: center !important;
    justify-items: center !important;
    text-align: center !important;
    padding: 24px !important;
    //width: 500px !important;
   // background-color: transparent !important;
  }