.pageTitle{
    padding: 0px 0px 2px 0px;
    
    height: 20px;
    padding-left: 0px;
    //margin-bottom: 20px;  
}

.profileslide{
    max-width: 10%;
}


