$primaryFont: 'PT Sans', sans-serif;
$primaryFontSize: 14px;
//Color Change this place for theme
$primary: #fe5728;
$primary900: #c2411d;
$secondary:#fe5728;


$red: #e90c10;
$gray: #6b6b6b;
$black: #000000;
$green: #4CAF50;
$accent: #ffb700;
$ambor:#ffbf00;
$cyan:cyan;
$white:white;
$purple :#a651f0;

// Screen sizes
$xxl: 1440px;
$xl: 1280px;
$l: 1024px;
$m: 768px;
$s:540px;

@mixin transition($val){
  transition: $val;
  -moz-transition: $val;
  -o-transition: $val;
  -webkit-transition: $val;
}
