.box{
    display: flex;
    justify-content:flex-end;
}

.status{
    min-width: 110px;
    text-align: center;
    background: #F4F4F4;
    padding: 5px;
    font-size: 12px;
    border-radius:4px;                
}
.reject{ 
    color: red !important;
} 
